import {mutate, get, perform} from "./types"

//-------------------------------
// STATE
//-------------------------------

const state = {
	autoSelectStep:    true,
	selectedStepIndex: null,
	screenshotLoaded:  false
}

//-------------------------------
// MUTATIONS
//-------------------------------

const mutations = {
	[mutate.PREVIEW_PANE.RESET](state) {
		state.selectedStepIndex = null
		state.autoSelectStep = true
		state.screenshotLoaded = false
	},
	[mutate.PREVIEW_PANE.ENABLE_AUTO_SELECT_STEP](state) {
		state.autoSelectStep = true
	},
	[mutate.PREVIEW_PANE.DISABLE_AUTO_SELECT_STEP](state) {
		state.autoSelectStep = false
	},
	[mutate.PREVIEW_PANE.SET_SELECTED_STEP_INDEX](state, stepNum) {
		state.selectedStepIndex = stepNum
	},
	[mutate.PREVIEW_PANE.IS_SCREENSHOT_LOADED](state, loaded) {
		state.screenshotLoaded = loaded
	},
}

//-------------------------------
// GETTERS
//-------------------------------
const getters = {
	[get.PREVIEW_PANE.TEST_RUN](state, getters, rootState, rootGetters) {
		return rootGetters[get.TEST_RUNNER_CONTROLLER.TEST_RUN]
	},
	[get.PREVIEW_PANE.SELECTED_STEP](state, getters) {

		let testRun = getters[get.PREVIEW_PANE.TEST_RUN]
		if (!_.isNil(testRun)) {
			if (!state.autoSelectStep && !_.isNil(state.selectedStepIndex)) { // if not auto select step and a step has been selected
				return testRun.getStep(state.selectedStepIndex)
			} 
			// fallback to last select step
			return testRun.lastExecutedStep
			
		}
		return null
	},
	[get.PREVIEW_PANE.IS_FIRST_STEP_SELECTED](state, getters) {
		let testRun = getters[get.PREVIEW_PANE.TEST_RUN]
		if (!_.isNil(testRun) && !_.isNil(state.selectedStepIndex)) {
			return state.selectedStepIndex === 0
		}
		return false
	},
	[get.PREVIEW_PANE.IS_LAST_STEP_SELECTED](state, getters) {
		let testRun = getters[get.PREVIEW_PANE.TEST_RUN]
		if (!_.isNil(testRun) && !_.isNil(state.selectedStepIndex)) {
			return state.selectedStepIndex === testRun.lastStepIndex
		}
		return false
	},
	[get.PREVIEW_PANE.SCREENSHOT](state, getters) {

		let screenshot = null

		let testRun = getters[get.PREVIEW_PANE.TEST_RUN]

		if (!_.isNil(testRun)) {
			if (!state.autoSelectStep && !_.isNil(state.selectedStepIndex)) {
				let step = testRun.steps[state.selectedStepIndex]
				screenshot = step.screenshot
			} else {
				// if auto select enabled or a step is not selected, get the latest image
				screenshot = testRun.latestImg
			}

		}

		if (screenshot === "AUTO_SCREENSHOT_DISABLED") {
			screenshot = null
		}

		return screenshot

	}
}

//-------------------------------
// ACTIONS
//-------------------------------
const actions = {
	[perform.PREVIEW_PANE.SELECT_PREVIOUS_STEP]({commit, state, getters}) {
		return new Promise((resolve) => {

			// get selected step
			let currentStep = getters[get.PREVIEW_PANE.SELECTED_STEP]

			if (!_.isNil(currentStep)) {

				// decrement step number
				let previousStepIndex = Math.max(0, currentStep.index - 1)
				commit(mutate.PREVIEW_PANE.SET_SELECTED_STEP_INDEX, previousStepIndex)

			}

			// resolve
			resolve(getters[get.PREVIEW_PANE.SELECTED_STEP])

		})
	},
	[perform.PREVIEW_PANE.SELECT_NEXT_STEP]({commit, state, getters}) {
		return new Promise((resolve) => {

			// get selected step
			let currentStep = getters[get.PREVIEW_PANE.SELECTED_STEP]

			if (!_.isNil(currentStep)) {

				let testRun = getters[get.PREVIEW_PANE.TEST_RUN]
				let nextStepIndex = Math.min(testRun.lastStepIndex, currentStep.index + 1)

				// get the next step
				let nextStep = testRun.getStep(nextStepIndex)

				// make sure that next step is not pending
				if (!nextStep.isPending && !nextStep.isTerminated) {
					commit(mutate.PREVIEW_PANE.SET_SELECTED_STEP_INDEX, nextStepIndex)
				}

			}

			// resolve
			resolve(getters[get.PREVIEW_PANE.SELECTED_STEP])
		})
	}
}

//-------------------------------
// STORE
//-------------------------------

export default {
	state,
	mutations,
	getters,
	actions
}

//--------------------------------------------------
// STATIC DEPENDENCIES
//--------------------------------------------------
import Vue from "vue"
import VueRouter from "vue-router"

//--------------------------------------------------
// Tracking
//--------------------------------------------------
import * as GA from "@uil/analytics/google_analytics"

//--------------------------------------------------
// Views
//--------------------------------------------------
import {NotFoundErrorPage} from "@uilicious/cake-ui"
import * as Views from "views"

//--------------------------------------------------
// ROUTE NAMES
//--------------------------------------------------
export const ROUTE = {
	STUDIO:                    "studio",
	//
	PROJECT_LIST:              "project-list",
	PROJECT_HOME:              "project",
	//
	PROJECT_EDITOR_BLANK:      "project/editor",
	PROJECT_EDITOR_TEST_BLANK: "project/editor/test/blank",
	PROJECT_EDITOR_TEST:       "project/editor/test"
}

//--------------------------------------------------
// ROUTER
//--------------------------------------------------

Vue.use(VueRouter)

if(!window.location.pathname.startsWith(__BASE_PATH__)){
	window.location.pathname = __BASE_PATH__
}

const router = new VueRouter({
	mode:   "history",
	base:   __BASE_PATH__, // this will be replaced by webpack
	routes: [
		{
			path:      "",
			component: Views.Studio,
			redirect:  {
				name: ROUTE.PROJECT_EDITOR_TEST_BLANK
			},
			children: [
				{
					path:      "",
					component: Views.EditorView,
					redirect:  {
						name: ROUTE.PROJECT_EDITOR_TEST_BLANK
					},
					children: [
						{
							name:      ROUTE.PROJECT_EDITOR_BLANK,
							path:      "",
							component: Views.ScriptPane
						},
						{
							name:      ROUTE.PROJECT_EDITOR_TEST,
							path:      ":nodeId",
							component: Views.ScriptPane
						}
					]
				}
			]
		},
		{
			path:      "/notFound",
			component: NotFoundErrorPage
		},
		{
			path:      "*",
			component: NotFoundErrorPage
		}
	]
})

//
// ROUTER HOOKS
//
router.beforeEach((to, from, next) => {
	const {website} = to.query
	if (to.name !== ROUTE.PROJECT_EDITOR_BLANK && website) {
		next({
			name:   ROUTE.PROJECT_EDITOR_BLANK,
			params: {},
			query:  to.query
		})

		return
	}

	if (to.name === ROUTE.PROJECT_EDITOR_BLANK && __INITIAL_TESTRUN_ID__ && !website) {
		next({
			name:   ROUTE.PROJECT_EDITOR_TEST,
			params: {
				nodeId: __INITIAL_TESTRUN_ID__
			},
			query: to.query
		})

		return
	}

	next()
})

router.afterEach((to, from) => {
	GA.trackPageView(window.location.pathname) // don't use to.path because it's relative from the base path
})

export default router



<template>
	<modal
		:title="title"
		:show-header="true"
		close-btn-text="Close"
		:show-confirm-btn="false"
		@close="close"
	>
		<div slot="content">
			<!--Share Link-->
			<section class="mb-m">
				<div class="flex-row flex-h-space-between">
					<h6
						for="share-link"
						class="mb-xs"
					>
						Share link
					</h6>
				</div>
				<div class="flex-row mb-xs">
					<cake-bs-text-field
						id="share-link"
						:value="embedUrl"
						readonly="true"
					/>
					<cake-button
						id="share-link-copy-btn"
						class="btn primary"
						data-clipboard-target="#share-link"
						@click="copyShareLink()"
					>
						<i class="fa fa-copy" /> {{ copiedLink ? "Copied!" : "Copy" }}
					</cake-button>
				</div>
			</section>

			<!--Share Embed-->
			<section class="">
				<h6 class="mb-xs">
					Share Embed
				</h6>

				<div class="flex-row">
					<!--iframe-->
					<div
						class="share-embed-iframe mr-s"
						v-html="iframeCode"
					/>
					<div class="flex-item-1">
						<!--iframe config-->
						<cake-form
							class="mb-m"
							:inline="[5,7]"
						>
							<h6>Configure Embed</h6>

							<cake-bs-text-field-group
								v-model="startAtStep"
								type="number"
								label="Start At Step"
								:inline="[5,7]"
							/>

							<cake-bs-field-group
								label="Autoplay"
								:inline="[5,7]"
							>
								<cake-switch
									v-model="autoplay"
								/>
							</cake-bs-field-group>
						</cake-form>

						<!--iframe code-->
						<h6>Embed Code</h6>
						<p
							for="share-embed-code"
							class="mb-xs"
						>
							Copy and paste this code on the page to embed.
						</p>
						<textarea
							id="share-embed-code"
							class="form-control mb-xs"
							rows="5"
							readonly
							:value="iframeCode"
						/>
						<cake-button
							id="share-embed-code-copy-btn"
							class="btn primary"
							data-clipboard-target="#share-embed-code"
							@click="copyShareEmbedCode()"
						>
							<i class="fa fa-copy" /> {{ copiedCode ? "Copied!" : "Copy" }}
						</cake-button>
					</div>
				</div>
			</section>
		</div>
	</modal>
</template>
<style lang='scss' rel='stylesheet/scss'>
@import '~@uilicious/cake-ui/src/scss/var';

#share-link {
  border-top-right-radius    : 0;
  border-bottom-right-radius : 0;
}

#share-link-copy-btn {
  border-top-left-radius    : 0;
  border-bottom-left-radius : 0;
}

#share-embed-code {
  width       : 100%;
  font-family : monospace;
  word-break  : break-all;
}

</style>
<script type='text/babel'>

//--------------------------------------------------
// Static dependencies
//--------------------------------------------------
import ClipboardJS from "clipboard"
import {trackShareLinkEvent, trackShareEmbedCodeEvent} from "@uil/analytics/google_analytics"

//--------------------------------------------------
// Component
//--------------------------------------------------
export default {
	//----------------------------------
	// Properties
	//----------------------------------
	props: {
		scriptName: {
			type:     String,
			required: true
		}
	},
	data: function () {
		return {
			url:         window.location.href,
			copiedLink:  false,
			copiedCode:  false,
			startAtStep: 1,
			autoplay:    true
		}
	},
	computed: {
		title: function () {
			return `Share "${this.scriptName}" Test`
		},
		embedUrl() {
			return `${__SNIPPET_EMBED_URL__.replace(":id", this.$route.params.nodeId)}?step=${this.startAtStep}&autoplay=${this.autoplay ? 1 : 0}`
		},
		iframeCode() {
			return `<iframe src="${this.embedUrl}" frameborder="0" width="600px" height="400px;" style="border: 1px solid #eeeeee;"></iframe>`
		},
	},
	//----------------------------------
	// Lifecycle Hooks
	//----------------------------------
	mounted() {
		this.$clipboard = []
		this.$clipboard[0] = new ClipboardJS("#share-link-copy-btn")
		this.$clipboard[1] = new ClipboardJS("#share-embed-code-copy-btn")
	},
	beforeDestroy() {
		if (this.$clipboard !== null) {
			this.$clipboard.forEach((cb) => {
				cb.destroy()
			})
		}
	},
	//----------------------------------
	// Methods
	//----------------------------------
	methods: {
		copyShareLink() {
			this.copiedLink = true
			trackShareLinkEvent()
		},
		copyShareEmbedCode() {
			this.copiedCode = true
			trackShareEmbedCodeEvent()
		},
		close() {
			this.$emit("close")
		}
	}
}
</script>

var render = function render(_c, _vm) {
  return _c("span", {
    staticClass: "browser-icon",
    class: _vm.data.staticClass,
    attrs: { "data-browser": _vm.props.browser, title: _vm.data.attrs.title },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import {formatDuration} from "@uilicious/cake-ui"
import {ITestRunStep, TestRunStepRecord, TestRunStepStatus} from "@uil/models/types"
import TestRun from "@uil/models/TestRun"

const STATUS: Record<string, TestRunStepStatus> = {
	PENDING:      "pending",
	SUCCESS:      "success",
	FAILURE:      "failure", // an assertion error
	ERROR:        "error", // something wrong with the test
	NO_SERVERS:   "no_servers", // system level error
	SYSTEM_ERROR: "system_error", // system level error
	TERMINATED:   "terminated"
}

const MODE: Record<string, ITestRunStep["mode"]> = {
	FETCH: "fetch",
	WARN:  "warn",
	ERROR: "error"
}

export default class TestRunStep implements ITestRunStep {
	public static STATUS = STATUS

	public logLevel
	public reason
	public tabs = []
	public viewportWidth
	public viewportHeight
	public currentTab
	public targetX
	public targetY
	public pageWidth
	public pageHeight
	public stepNum
	public file
	public fullScreenshot

	// Private variables
	private _testRun: TestRun
	private _index: number
	private _cmd: string
	private _arg: string[]
	private _description: string
	private _mode: ITestRunStep["mode"]
	private _timestamp: number
	private _final: boolean
	private _beforeImg: string
	private _beforeActionImg: string
	private _beforeActionImgPreloaded: boolean
	private _afterImg: string
	private _afterActionImg: string
	private _afterImgPreloaded: boolean
	private _title: string
	private _url: string
	private _status: TestRunStepStatus
	private _error: string
	private _time: number

	constructor(testRun: TestRun, index: number, o: TestRunStepRecord) {
		if (o == null) {
			throw new Error("Cannot initialise TestRun object with null")
		}

		// set all values
		Object.keys(o).forEach((k) => {
			this[k] = o[k]
		})

		// step info
		this._testRun = testRun
		this._index = index

		// set defaults
		// step config
		this.cmd = o.cmd || ""
		this.arg = o.arg || []
		this.description = o.description || ""
		this.mode = o.mode || MODE.WARN
		this.logLevel = o.logLevel || "normal"

		// step results
		this.status = o.status || STATUS.PENDING
		this.error = o.error || o.reason || ""
		this.final = typeof o.final === "boolean" ? o.final : false
		this.time = o.time || null

		// step img
		this.beforeImg = o.beforeImg || null

		this.beforeActionImg = o.beforeActionImg || null
		this.beforeActionImgPreloaded = false

		this.afterImg = o.afterImg || null
		this.afterImgPreloaded = false

		// step extra results
		this.title = o.title || ""
		this.url = o.url || ""
	}

	//--------------------------------------------------
	// Accessors
	//--------------------------------------------------

	get testRun() {
		return this._testRun
	}

	set testRun(value) {
		this._testRun = value
	}

	get index() {
		return this._index
	}

	set index(value) {
		this._index = value
	}

	get cmd() {
		return this._cmd
	}

	set cmd(value) {
		this._cmd = value
	}

	get arg() {
		return this._arg
	}

	set arg(value) {
		this._arg = value
	}

	get description() {
		return this._description
	}

	set description(value) {
		this._description = value
	}

	get mode() {
		return this._mode
	}

	set mode(value) {
		this._mode = value
	}

	get timestamp() {
		return this._timestamp
	}

	set timestamp(value) {
		this._timestamp = value
	}

	get final() {
		return this._final
	}

	set final(value) {
		this._final = value
	}

	get beforeImg() {
		return this._beforeImg
	}

	set beforeImg(value) {
		this._beforeImg = value
	}

	get beforeActionImg() {
		return this._beforeActionImg
	}

	set beforeActionImg(value) {
		this._beforeActionImg = value
	}

	get beforeActionImgPreloaded() {
		return this._beforeActionImgPreloaded
	}

	set beforeActionImgPreloaded(value) {
		this._beforeActionImgPreloaded = value
	}

	get afterImg() {
		return this._afterImg
	}

	set afterImg(value) {
		this._afterImg = value
	}

	get afterImgPreloaded() {
		return this._afterImgPreloaded
	}

	set afterImgPreloaded(value) {
		this._afterImgPreloaded = value
	}

	/**
	 * Get the type of screenshot to display
	 * - If there's a before action screenshot, use that
	 * - Else use the after step screenshot
	 */
	get screenshotType() {
		if (
			typeof this.beforeActionImg === "string" &&
			this.beforeActionImg !== ""
		) {
			return "beforeActionImg"
		}
		if (typeof this.afterImg === "string" && this.afterImg !== "") {
			return "afterImg"
		}
		return null
	}

	/**
	 * Get the screenshot to display for this step
	 */
	get screenshot() {
		if(this.screenshotType){
			return this[this.screenshotType] || ""
		}
		return ""
	}

	/**
	 * Is the screenshot unavailable?
	 */
	get isScreenshotUnavailable() {
		return this[this.screenshotType + "IsUnavailable"] || !this.screenshot
	}

	/**
	 * Reason why screenshot is unavailable
	 */
	get screenshotUnavailableReason() {
		if (this.isScreenshotUnavailable) {
			const reasonCode = this[this.screenshotType + "IsUnavailableReason"]
			if (reasonCode === "SCREENSHOTS_DISABLED") {
				return "Screenshots has been disabled at this step"
			} else if (reasonCode === "SCREENSHOT_BLOCKED_BY_ALERT") {
				return "Screenshot is blocked by alert at this step"
			} else if(this.status === "terminated"){
				return "Screenshot is not available because test was terminated at this step"
			}
			return "Screenshot is not available"
		}
		return ""
	}

	get title() {
		return this._title
	}

	set title(value) {
		this._title = value
	}

	get url() {
		return this._url
	}

	set url(value) {
		this._url = value
	}

	get status() {
		return this._status
	}

	set status(value) {
		this._status = value
	}

	get error() {
		return this._error
	}

	set error(value) {
		this._error = value
	}

	get time() {
		return this._time
	}

	set time(value) {
		this._time = value
	}

	get timeTaken() {
		return this.time
	}

	set timeTaken(value) {
		this.time = value
	}

	//--------------------------------------------------
	// Computed properties
	//--------------------------------------------------

	get isDebug() {
		return this.logLevel === "debug"
	}

	get isInfo() {
		return this.logLevel === "info"
	}

	get isNormal() {
		return this.logLevel === "normal"
	}

	get isFetch() {
		return this.mode === MODE.FETCH
	}

	get isTerminated() {
		return this.status === STATUS.TERMINATED
	}

	get isPending() {
		return this.status === STATUS.PENDING
	}

	get isSuccess() {
		return this.status === STATUS.SUCCESS
	}

	get isFailure() {
		return this.status === STATUS.FAILURE
	}

	get isWarning() {
		return this.status === STATUS.NO_SERVERS
		//return this.status === STATUS.FAILURE && !this.isFinal;
	}

	get isError() {
		return (
			this.status === STATUS.ERROR || this.status === STATUS.SYSTEM_ERROR
		)
	}

	/**
	 * Get formatted time taken to execute the step
	 */
	get formattedTimeTaken() {
		if (!this.isPending) {
			try {
				return formatDuration(this.timeTaken, 1)
			} catch (e) {
				// do nothing
			}
		}
		return ""
	}

	//--------------------------------------------------
	// Instance methods
	//--------------------------------------------------
	update(o) {
		Object.keys(o).forEach((k) => {
			const value = o[k]
			if (k === "reason") {
				this.reason = value
				this.error = value
			} else {
				this[k] = value
			}
		})
	}
}

TestRunStep.STATUS = STATUS

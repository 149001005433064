<template functional>
    <label>
        <browser-icon :browser="props.browser"></browser-icon>
        <span>
            {{
                props.browser === "chrome" ? "Chrome" :
                props.browser === "firefox" ? "Firefox" :
                props.browser === "safari" ? "Safari" :
                props.browser === "edge" ? "Edge" :
                props.browser === "ie11" ? "IE 11" :
                "-"
            }}
        </span>
    </label>
</template>
<script>
	export default {
		props: {
			browser: {
				type:     String,
				required: true
			}
		}
	};
</script>

<style scoped>

</style>

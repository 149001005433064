import TestRun from "@uil/models/TestRun"
import {mutate, get, perform} from "./types"

const state = {}

const mutations = {
	/**
	 * Save a test run to store
	 * @param state
	 * @param {TestRun} testRun
	 */
	[mutate.TEST_RUNS.SET_TEST_RUN](state, testRun) {
		if (!_.isNil(testRun)) {
			Vue.set(state, testRun.id, testRun)
		}
	},
	/**
	 * Update a test run
	 * @param state
	 * @param id
	 * @param report
	 */
	[mutate.TEST_RUNS.UPDATE_TEST_RUN_REPORT](state, {id, report}) {
		let testRun = state[id]
		if (!_.isNil(testRun)) {
			testRun.update(report)
		}
	}
}

const getters = {
	[get.TEST_RUNS.LIST]: state => _.values(state)
}

const actions = {
	[perform.TEST_RUNS.CREATE_TEST_RUN]({commit}, {id, testId, browser, resolution}) {
		return new Promise((resolve) => {
			let testRun = new TestRun({
				id, testId, browser, resolution
			})
			commit(mutate.TEST_RUNS.SET_TEST_RUN, testRun)
			resolve(testRun)
		})
	},
	[perform.TEST_RUNS.UPDATE_TEST_RUN]({commit}, {id, report}) {
		return new Promise((resolve, reject) => {
			let testRun = state[id]
			if (_.isNil(testRun)) {
				reject(new Error(`Error updating test run in store: test run ${id} not found `))
			} else {
				commit(mutate.TEST_RUNS.UPDATE_TEST_RUN_REPORT, {id, report})
				testRun = state[id]
				resolve(testRun)
			}
		})
	}
}

export default {
	state,
	mutations,
	getters,
	actions
}

var render = function render(_c, _vm) {
  return _c(
    "label",
    [
      _c("browser-icon", { attrs: { browser: _vm.props.browser } }),
      _vm._v(" "),
      _c("span", [
        _vm._v(
          "\n        " +
            _vm._s(
              _vm.props.browser === "chrome"
                ? "Chrome"
                : _vm.props.browser === "firefox"
                ? "Firefox"
                : _vm.props.browser === "safari"
                ? "Safari"
                : _vm.props.browser === "edge"
                ? "Edge"
                : _vm.props.browser === "ie11"
                ? "IE 11"
                : "-"
            ) +
            "\n    "
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
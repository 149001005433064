let CONFIG = {
	CDN: {
		enabled: false,
		domain:  null
	},
	// expect: "https://api.uilicious.com/v2/project/testrun/image?id=:id&image=:image"
	url: ""
}

export default {
	config(opts) {
		if (typeof opts !== "undefined") {
			if (typeof opts === "object" && opts !== null) {
				CONFIG = Object.assign(CONFIG, opts)
			}
		} else {
			return CONFIG
		}
		
	},
	get(testRunId, testRunImage, desiredWidth, useFallback = false) {
		if (!testRunId) {
			console.error("missing `testRunId` parameter")
			return ""
		}

		if (!testRunImage) {
			console.error("missing `testRunImage` parameter")
			return ""
		}

		if (!desiredWidth) {
			desiredWidth = null
		}

		if (typeof desiredWidth === "string") {
			if (desiredWidth.endsWith("px")) {
				desiredWidth.replace("px", "")
			}
			desiredWidth = parseInt(desiredWidth)
		}

		// image width breakpoints for cache optimisation
		let breakpoints = [
			// 5400,
			// 4096,
			// 2560 /* not a multiple of 120 */,
			// 1920 /* not a multiple of 120 */,
			// 1440,
			// 1280 /* not a multiple of 120 */,
			// 1080,
			// 1024 /* not a multiple of 120 */,
			//---------------------------
			// At any desired width more
			// than this breakpoint,
			// render the original size
			//---------------------------
			960,
			// 840,
			768 /* not a multiple of 120 */,
			// 720,
			// 600,
			480,
			// 360,
			240,
			120
		]

		if (CONFIG.CDN.enabled && !useFallback) {
			if (!CONFIG.CDN.domain) {
				console.log.error("missing image cdn domain configuration")
			} else {
				let size = "x" // "x" loads the original size

				// Pick a width that matches the breakpoint we want
				if (desiredWidth != null && desiredWidth <= breakpoints[0]) {
					for (let i = 0; i < breakpoints.length; i++) {
						if (breakpoints[i] >= desiredWidth) {
							size = breakpoints[i] + "x"
						} else {
							break
						}
					}
				}

				// generate url
				return `${CONFIG.CDN.domain}/imgs/${size}/${testRunId}/${encodeURIComponent(testRunImage)}`
			}
		}

		// fallback to using api.js route
		return CONFIG.url.replace(":id", testRunId).replace(":image", encodeURIComponent(testRunImage))
	}
}

export const mutate = {}
export const get = {}
export const perform = {}

//-----------------------------------
// EDITOR VIEW
//-----------------------------------
mutate.EDITOR_VIEW = {
	SET_MOBILE_VIEW_ACTIVE_PANE: "editor/MOBILE_VIEW/ACTIVE_PANE",
}

get.EDITOR_VIEW = {}

perform.EDITOR_VIEW = {}

//-----------------------------------
// TEST RUNS
//-----------------------------------
mutate.TEST_RUNS = {
	SET_TEST_RUN:           "testRuns/SET_TEST_RUN",
	UPDATE_TEST_RUN_STATUS: "testRuns/UPDATE_TEST_RUN_STATUS",
	UPDATE_TEST_RUN_REPORT: "testRuns/UPDATE_TEST_RUN_REPORT"
}

get.TEST_RUNS = {
	LIST: "testRuns/LIST"
}

perform.TEST_RUNS = {
	CREATE_TEST_RUN: "testRuns/CREATE_TEST_RUN",
	UPDATE_TEST_RUN: "testRuns/UPDATE_TEST_RUN"
}

//-----------------------------------
// SCRIPT EDITOR
//-----------------------------------
mutate.SCRIPT_EDITOR = {
	SET_STATUS:    "editor/SET_STATUS",
	SET_PRISTINE:  "editor/SET_PRISTINE",
	SET_MODIFIED:  "editor/SET_MODIFIED",
	LAST_MODIFIED: "editor/LAST_MODIFIED",
}

get.SCRIPT_EDITOR = {}

perform.SCRIPT_EDITOR = {}

//-----------------------------------
// TEST RUNNER CONTROLLER
//-----------------------------------
mutate.TEST_RUNNER_CONTROLLER = {
	SET_INITIALISING:  "testRunnerController/SET_INITIALISING",
	SET_BROWSER:       "testRunnerController/SET_BROWSER",
	SET_RESOLUTION:    "testRunnerController/SET_RESOLUTION",
	SET_TEST_RUN_ID:   "testRunnerController/SET_TEST_RUN_ID",
	UNSET_TEST_RUN_ID: "testRunnerController/UNSET_TEST_RUN_ID",
	SET_SCRIPT:        "testRunnerController/SET_SCRIPT",
	SET_SCRIPT_NAME:   "testRunnerController/SET_SCRIPT_NAME",
}

get.TEST_RUNNER_CONTROLLER = {
	TEST_RUN: "testRunnerController/TEST_RUN",
}

perform.TEST_RUNNER_CONTROLLER = {}

//-----------------------------------
// PREVIEW PANE
//-----------------------------------
mutate.PREVIEW_PANE = {
	RESET:                    "previewPane/RESET",
	SET_LOADING_STATUS:       "previewPane/SET_LOADING_STATUS",
	IS_SCREENSHOT_LOADED:     "previewPane/IS_SCREENSHOT_LOADED",
	SET_TEST_RUN_ID:          "previewPane/SET_TEST_RUN_ID",
	ENABLE_AUTO_SELECT_STEP:  "previewPane/ENABLE_AUTO_SELECT_STEP",
	DISABLE_AUTO_SELECT_STEP: "previewPane/DISABLE_AUTO_SELECT_STEP",
	SET_SELECTED_STEP_INDEX:  "previewPane/SET_SELECTED_STEP_INDEX",
	WINDOW:                   {
		SET_URL:        "previewPane/window/SET_URL",
		SET_RESOLUTION: "previewPane/window/SET_RESOLUTION"
	}
}

get.PREVIEW_PANE = {
	TEST_RUN:               "previewPane/testRun",
	SELECTED_STEP:          "previewPane/selectedStep",
	IS_FIRST_STEP_SELECTED: "previewPane/isFirstStepSelected",
	IS_LAST_STEP_SELECTED:  "previewPane/isLastStepSelected",
	SCREENSHOT:             "previewPane/screenshot"
}

perform.PREVIEW_PANE = {
	SELECT_PREVIOUS_STEP: "previewPane/SELECT_PREVIOUS_STEP",
	SELECT_NEXT_STEP:     "previewPane/SELECT_NEXT_STEP",
	WINDOW:               {
		SET_URL:        "previewPane/window/SET_URL",
		SET_RESOLUTION: "previewPane/window/SET_RESOLUTION"
	}
}

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      attrs: {
        title: _vm.title,
        "show-header": true,
        "close-btn-text": "Close",
        "show-confirm-btn": false,
      },
      on: { close: _vm.close },
    },
    [
      _c("div", { attrs: { slot: "content" }, slot: "content" }, [
        _c("section", { staticClass: "mb-m" }, [
          _c("div", { staticClass: "flex-row flex-h-space-between" }, [
            _c("h6", { staticClass: "mb-xs", attrs: { for: "share-link" } }, [
              _vm._v("\n\t\t\t\t\tShare link\n\t\t\t\t"),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex-row mb-xs" },
            [
              _c("cake-bs-text-field", {
                attrs: {
                  id: "share-link",
                  value: _vm.embedUrl,
                  readonly: "true",
                },
              }),
              _vm._v(" "),
              _c(
                "cake-button",
                {
                  staticClass: "btn primary",
                  attrs: {
                    id: "share-link-copy-btn",
                    "data-clipboard-target": "#share-link",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.copyShareLink()
                    },
                  },
                },
                [
                  _c("i", { staticClass: "fa fa-copy" }),
                  _vm._v(
                    " " +
                      _vm._s(_vm.copiedLink ? "Copied!" : "Copy") +
                      "\n\t\t\t\t"
                  ),
                ]
              ),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("section", {}, [
          _c("h6", { staticClass: "mb-xs" }, [
            _vm._v("\n\t\t\t\tShare Embed\n\t\t\t"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "flex-row" }, [
            _c("div", {
              staticClass: "share-embed-iframe mr-s",
              domProps: { innerHTML: _vm._s(_vm.iframeCode) },
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "flex-item-1" },
              [
                _c(
                  "cake-form",
                  { staticClass: "mb-m", attrs: { inline: [5, 7] } },
                  [
                    _c("h6", [_vm._v("Configure Embed")]),
                    _vm._v(" "),
                    _c("cake-bs-text-field-group", {
                      attrs: {
                        type: "number",
                        label: "Start At Step",
                        inline: [5, 7],
                      },
                      model: {
                        value: _vm.startAtStep,
                        callback: function ($$v) {
                          _vm.startAtStep = $$v
                        },
                        expression: "startAtStep",
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "cake-bs-field-group",
                      { attrs: { label: "Autoplay", inline: [5, 7] } },
                      [
                        _c("cake-switch", {
                          model: {
                            value: _vm.autoplay,
                            callback: function ($$v) {
                              _vm.autoplay = $$v
                            },
                            expression: "autoplay",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("h6", [_vm._v("Embed Code")]),
                _vm._v(" "),
                _c(
                  "p",
                  { staticClass: "mb-xs", attrs: { for: "share-embed-code" } },
                  [
                    _vm._v(
                      "\n\t\t\t\t\t\tCopy and paste this code on the page to embed.\n\t\t\t\t\t"
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("textarea", {
                  staticClass: "form-control mb-xs",
                  attrs: { id: "share-embed-code", rows: "5", readonly: "" },
                  domProps: { value: _vm.iframeCode },
                }),
                _vm._v(" "),
                _c(
                  "cake-button",
                  {
                    staticClass: "btn primary",
                    attrs: {
                      id: "share-embed-code-copy-btn",
                      "data-clipboard-target": "#share-embed-code",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.copyShareEmbedCode()
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "fa fa-copy" }),
                    _vm._v(
                      " " +
                        _vm._s(_vm.copiedCode ? "Copied!" : "Copy") +
                        "\n\t\t\t\t\t"
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
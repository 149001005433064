//--------------------------------------------------
// Static dependencies
//--------------------------------------------------
import {EDITOR_STATUS} from "enum"
import {mutate, get, perform} from "./types"

//-------------------------------
// STATE
//-------------------------------

const state = {
	status:       EDITOR_STATUS.LOADING,
	pristine:     true, // set pristine if script never been edited
	modified:     false, // set modified if there are unsaved changes
	lastModified: null,
}

//-------------------------------
// MUTATIONS
//-------------------------------

const mutations = {
	[mutate.SCRIPT_EDITOR.SET_STATUS](state, status) {
		if (!_.isNil(status)) {
			state.status = status
		}
	},
	[mutate.SCRIPT_EDITOR.SET_PRISTINE](state, pristine) {
		state.pristine = pristine
	},
	[mutate.SCRIPT_EDITOR.SET_MODIFIED](state, modified) {
		state.modified = modified
	},
	[mutate.SCRIPT_EDITOR.LAST_MODIFIED](state, lastModified) {
		state.lastModified = lastModified
	}
}

//-------------------------------
// GETTERS
//-------------------------------

const getters = {}

//-------------------------------
// ACTIONS
//-------------------------------

const actions = {}

//-------------------------------
// STORE
//-------------------------------

export default {
	state,
	mutations,
	getters,
	actions
}

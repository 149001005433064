export const EDITOR_STATUS = {
	LOADING: "LOADING", // when the script has not loaded, or the editor is blank
	READY:   "READY", // when the script is loaded to the editor
	SAVING:  "SAVING" // when a script is being saved. during this time, avoid resetting the editor or changing the test script
}

export const BROWSER = {
	CHROME:               "chrome",
	FIREFOX:              "firefox",
	SAFARI:               "safari",
	MICROSOFT_EDGE:       "edge",
	INTERNET_EXPLORER_11: "ie11"
}

export const SUPPORTED_RESOLUTIONS = {
	[BROWSER.CHROME]: [
		"2560x1440", "1920x1080", "1600x1200", "1440x900", "1280x960", "1024x768", "768x1024", "480x854", "414x736", "375x667", "320x568"
	],
	[BROWSER.FIREFOX]: [
		"2560x1440", "1920x1080", "1600x1200", "1440x900", "1280x960", "1024x768", "768x1024", "480x854", "414x736", "375x667", "320x568"
	],
	[BROWSER.SAFARI]: [
		"1920x1080", "1600x1200", "1280x960", "1024x768"
	],
	[BROWSER.MICROSOFT_EDGE]: [
		"2560x1440", "1920x1080", "1600x1200", "1440x900", "1280x960", "1024x768", "768x1024", "480x854", "414x736", "375x667", "320x568"
	],
	[BROWSER.INTERNET_EXPLORER_11]: [
		"1600x1200", "1440x900", "1280x960", "1024x768"
	]
}

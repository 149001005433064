//
// Default open graph tags
//
$("meta[property=\"og:url\"]")[0].content = window.location.href
$("meta[property=\"og:image\"]")[0].content = __SNIPPET_URL__ + "/img/logo-brand.png"

//
// Studio Component
//
export const Studio = Vue.component("Studio", function () {
	return import("./Studio.vue")
})

//
// Project Editor View Component
//
export const EditorView = Vue.component("EditorView", function () {
	return import("./ProjectEditorView/ProjectEditorView.vue")
})

//
// Script Pane Component
//
export const ScriptPane = Vue.component("ScriptPane", function () {
	return import("./ProjectEditorView/ScriptPane/ScriptPane.vue")
})

//
// Preview Pane Component
//
export const PreviewPane = Vue.component("PreviewPane", function () {
	return import("./ProjectEditorView/PreviewPane/PreviewPane.vue")
})

<template functional>
	<span class="browser-icon"
	      :class="data.staticClass"
	      :data-browser="props.browser"
	      :title="data.attrs.title"
	></span>
</template>
<style lang='scss' rel='stylesheet/scss'>
	.browser-icon {

		&::before{
			content: '\00A0' // no-break space
		}

		width: 1em;
		display: inline-block;
		background-repeat: no-repeat;
		background-position: center;
		background-size: 1em 1em;

		&[data-browser='chrome']{
			background-image: url("../../assets/browser-icons/chrome.svg");
		}
		&[data-browser='firefox']{
			background-image: url("../../assets/browser-icons/firefox.svg");
		}
		&[data-browser='safari']{
			background-image: url("../../assets/browser-icons/safari.svg");
		}
		&[data-browser='edge']{
			background-image: url("../../assets/browser-icons/microsoft-edge.svg");
		}
		&[data-browser='edgechromium']{
			background-image: url("../../assets/browser-icons/microsoft-edge-chromium.svg");
		}
		&[data-browser='ie11']{
			background-image: url("../../assets/browser-icons/internet-explorer.svg");
		}

	}
</style>
<script>
	export default {
		props:    {
			browser: {
				type: String,
				required: true
			},
		}
	};
</script>

/*

Register FREQUENTLY used components as global components here

*/

import Vue from "vue"
import {
	Breadcrumb,
	CakeBsFieldGroup,
	CakeBsSelectField,
	CakeBsSelectFieldGroup,
	CakeSelectField,
	CakeBsTextField,
	CakeBsTextFieldGroup,
	CakeButton,
	CakeDropdownButton,
	CakeDropdownField,
	CakeEditableText,
	CakeForm,
	CakeSwitch,
	CakeTabPane,
	CakeTag,
	CakeToggleField
} from "@uilicious/cake-ui"
import BrowserIcon from "@uil/components/icons/BrowserIcon"
import BrowserLabel from "@uil/components/labels/BrowserLabel"
import ShareModal from "@/views/modals/ShareModal"
import SignUpModal from "@/views/modals/SignUpModal/SignUpModal"

let installed = false

if (!installed) {

	//--------------------------------------------------
	//
	// CORE
	//
	//--------------------------------------------------

	//--------------------------------------------------
	// Button
	//--------------------------------------------------
	Vue.component("CakeButton", CakeButton)

	//--------------------------------------------------
	// Tag
	//--------------------------------------------------
	Vue.component("CakeTag", CakeTag)

	//--------------------------------------------------
	// Form
	//--------------------------------------------------

	Vue.component("CakeForm", CakeForm)

	Vue.component("CakeToggleField", CakeToggleField)
	Vue.component("CakeSwitch", CakeSwitch)

	//--------------------------------------------------
	// Form (Bootstrap)
	//--------------------------------------------------

	Vue.component("CakeBsFieldGroup", CakeBsFieldGroup)

	Vue.component("CakeBsTextField", CakeBsTextField)
	Vue.component("CakeBsTextFieldGroup", CakeBsTextFieldGroup)

	Vue.component("CakeBsSelectField", CakeBsSelectField)
	Vue.component("CakeBsSelectFieldGroup", CakeBsSelectFieldGroup)
	Vue.component("CakeSelectField", CakeSelectField)

	Vue.component("CakeDropdownField", CakeDropdownField)

	Vue.component("CakeEditableText", CakeEditableText)

	//--------------------------------------------------
	// DropdownMenu
	//--------------------------------------------------

	Vue.component("DropdownMenu", CakeDropdownButton)

	//--------------------------------------------------
	// Nav
	//--------------------------------------------------
	Vue.component("Breadcrumb", Breadcrumb)

	//--------------------------------------------------
	// Icons / Labels
	//--------------------------------------------------
	Vue.component("BrowserIcon", BrowserIcon)
	Vue.component("BrowserLabel", BrowserLabel)

	//--------------------------------------------------
	// Pane
	//--------------------------------------------------
	Vue.component("CakeTabPane", CakeTabPane)

	//--------------------------------------------------
	//
	// Application Components
	//
	//--------------------------------------------------


	//--------------------------------------------------
	// Modals
	//--------------------------------------------------
	Vue.component("ShareModal", ShareModal)
	Vue.component("SignUpModal", SignUpModal)

	installed = true

}






//--------------------------------------------------
//
// DEPENDENCIES
//
//--------------------------------------------------

// Global depencies
import "@/vendor"

// API
import TestRunImage from "@uil/services/TestRunImage"

// Vue
import Vue from "vue"

import {default as store} from "store"
import router from "router"
import {sync} from "vuex-router-sync"

import DurationFilter from "@uil/filters/duration"
import DatetimeFilter from "@uil/filters/datetime"
import hhmmzFilter from "@uil/filters/hhmmz"
import RelativeTimeFilter from "@uil/filters/relativeTime"

import "@uilicious/cake-ui/dist/style.css"

import {
	CakePlugin,
	CakeDirectives,
} from "@uilicious/cake-ui"

import "@/global_components"
import "@/global_async_components"
import "@/views"

// Analytics
// import * as GA from "@uil/analytics/google_analytics"
import * as Intercom from "@uil/analytics/intercom"
import * as Analytics from "@uil/analytics/module"

const $api = window.api

//--------------------------------------------------
//
// Helper functions
//
//--------------------------------------------------

function setCookie(name, value, days) {
	let expires = ""
	if (days) {
		let date = new Date()
		date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000))
		expires = "; expires=" + date.toUTCString()
	}
	document.cookie = name + "=" + (value || "") + expires + "; path=/"
}

function getCookie(name) {
	let nameEQ = name + "="
	let ca     = document.cookie.split(";")
	for (let i = 0; i < ca.length; i++) {
		let c = ca[i]
		while (c.charAt(0) === " ") {
			c = c.substring(1, c.length)
		}
		if (c.indexOf(nameEQ) === 0) {
			return c.substring(nameEQ.length, c.length)
		}
	}
	return null
}

//--------------------------------------------------
//
// Global functions
//
//--------------------------------------------------

window.STUDIO = {
	/**
	 * Enable developer preview mode
	 * This will show features that will be released to production, but is experimental
	 */
	enableDeveloperPreview() {
		document.cookie = "devPreview=1;max-age=214748364" // never expires (almost)
		location.reload()
	},
	/**
	 * Disable developer preview mode
	 * This will hide features that will be released to production, but is experimental
	 */
	disableDeveloperPreview() {
		document.cookie = "devPreview=0;max-age=2147483647" // never expires (almost)
		location.reload()
	},
	isDeveloperPreviewEnabled() {
		let devPreview = document.cookie.replace(/(?:(?:^|.*;\s*)devPreview\s*=\s*([^;]*).*$)|^.*$/, "$1")
		return devPreview === "1"
	},
	/**
	 * Redirect to login page and append current location as the `sourceurl` parameter
	 */
	redirectToLoginPage(redirect = false) {
		let url = __ACCOUNT_PORTAL_HOST__
		if (redirect) {
			let redirectParam = window.location.href.replace(window.location.origin, "")
			url += "?goTo=__ws__" + redirectParam
		}
		location.href = url
	}
}

//--------------------------------------------------
//
// Initialisation logs
//
//--------------------------------------------------
console.info(process.env.NODE_ENV)
console.info("Welcome to UI-licious Test!")
console.info("Version: " + __VERSION__) // actual value of __VERSION__ will be replaced by webpack
console.info("Deployed on: " + __BUILD_TIME__) // actual value of __BUILD_TIME__ will be replaced by webpack
console.info("Built with VueJS: " + Vue.version)
if (window.STUDIO.isDeveloperPreviewEnabled()) {
	console.warn("Developer Preview enabled. Warning: You are now seeing experimental features that we are currently testing, these are not stable, and you are advised against using them for serious stuff as of now.")
}

//--------------------------------------------------
//
// Setup API
//
//--------------------------------------------------

// api


try {
	// if baseurl is not false-y, override the api
	if (__UILICIOUS_API_BASEURL__) { // this value is injected by webpack during compilation
		$api._core.baseURL(__UILICIOUS_API_BASEURL__)
	}

} catch (e) {
	console.error("Error initialising UI-licious API client library: ", e)
	throw e
}

TestRunImage.config({
	CDN: {
		enabled: false, //__IMAGE_CDN_ENABLED__,
		domain:  null // __IMAGE_CDN_DOMAIN__
	},
	// expect: "https://api.uilicious.com/v2/project/testrun/image?id=:id&image=:image"
	url: __API_SNIPPET_BASE_URL__ + "/api/v2/snippet/:id/image/:image"
})

Vue.mixin({
	beforeCreate() {
		this.$api = $api
	}
})

//--------------------------------------------------
// Setup Vue application
//--------------------------------------------------
// Expose api to all components for convenience.

// Vuex-router-sync
sync(store, router)

// CakePlugin
Vue.use(CakePlugin)
Vue.directive("Tooltip", CakeDirectives.Tooltip)
Vue.directive("OnClickAway", CakeDirectives.OnClickAway)
Vue.directive("DeveloperPreview", CakeDirectives.DeveloperPreview)

//-----------------------------
// Filters
//-----------------------------
Vue.use(DurationFilter)
Vue.use(DatetimeFilter)
Vue.use(RelativeTimeFilter)
Vue.use(hhmmzFilter)

//-----------------------------
// Mixin
//-----------------------------

Vue.mixin({
	beforeCreate() {

		// cookie management methods
		this.$cookie = {
			set: setCookie,
			get: getCookie
		}

		// pass the event hub down to descendents
		if (!this.$eventHub && this.$root.$eventHub) {
			this.$eventHub = this.$root.$eventHub
		}

		// pass the $redirectToNotFoundPage method from root to all vue components
		if (!this.$redirectToNotFoundPage && this.$root.$redirectToNotFoundPage) {
			this.$redirectToNotFoundPage = this.$root.$redirectToNotFoundPage
		}

	}
})

//--------------------------------------------------
// Initialise root <Studio> application after
// setting up directives and plugins
//--------------------------------------------------

initialiseStudio()
initialiseAnalytics()

/**
 * Initialise the root <Studio> application
 */
function initialiseStudio() {

	Vue.mixin({
		beforeCreate () {
			// Expose to all components
			this.$onPremise = false
			this.$onPremiseConfig = {}
			this.$hasSpaceFeature = () => false
		},
	})

	// Root Vue Component
	// todo: merge this with Studio.vue?
	const app = new Vue({
		store,
		router,
		// custom plugins
		created() {
			//--------------------------------------------------
			// Register event Hub
			//--------------------------------------------------
			this.$eventHub = new Vue({
				name:       "EventHub",
				parent:     this,
				functional: true
			})

			//--------------------------------------------------
			// Register root methods
			//--------------------------------------------------

			// redirect to not found page
			this.$redirectToNotFoundPage = function (err) {
				// log
				if (err) {
					console.error(err)
				}
				// redirect
				if (this.$router) {
					this.$router.push({path: "/notFound", query: {path: this.$router.currentRoute.fullPath}})
				} else {
					location.href = "/notFound?path=" + encodeURIComponent(location.href)
				}
			}
		},
		beforeDestroy() {

			// destroy the event hub
			this.$eventHub.$destroy()

		}
	})

	app.$mount("#main")

}

// import user analytics module and initialise it
async function initialiseAnalytics(){
	const analyticsModule = (await import("@/../modules/marketing-analytics/lib/uilc_analytics")).default

	if (analyticsModule) {
		analyticsModule.install(window, $api)
	}
}

//--------------------------------------------------
// widgets
//--------------------------------------------------

// GA.install()

// Global variable __INTERCOM_ENABLED__ is being set from config/index.js
Intercom.install(__INTERCOM_ENABLED__); // eslint-disable-line
let guestUser = {}
Intercom.login(guestUser)
Analytics.install()

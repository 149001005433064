//--------------------------------------------------
// Dependencies
//--------------------------------------------------
import Vue from "vue"
import Vuex from "vuex"

import editorView from "./EditorViewStore"
import previewPane from "./PreviewPaneStore"
import scriptEditor from "./ScriptEditorStore"
import testRunnerController from "./TestRunnerControllerStore"
import testRuns from "./TestRunsStore"
//--------------------------------------------------
// Store
//--------------------------------------------------

Vue.use(Vuex)

let store = new Vuex.Store({
	modules: {
		editorView,
		previewPane,
		scriptEditor,
		testRunnerController,
		testRuns,
	}
})

export default store

// Re-export types
export {mutate, get, perform} from "./types"

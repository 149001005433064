<template>
	<modal
		title="Create an account"
		:enable-mask-dismissal="true"
		:show-header="false"
		:show-footer="false"
		:pad-content="false"
		@close="close"
	>
		<div
			slot="content"
			class="sign-up-view"
		>
			<!-- Feature -->
			<section class="sign-up-view-feature-section">
				<header class="text-center">
					<h3 class="mb-xs">
						<b>Go Pro</b> with UI-licious Studio
					</h3>
					<p>
						Automate user journey testing and ship with confidence!
					</p>
				</header>

				<div class="feature-img-box">
					<transition
						name="fade"
						mode="out-in"
					>
						<img
							:key="currentFeatureImg"
							class="feature-img"
							:src="currentFeatureImg"
							alt="App screenshot"
						>
					</transition>
					<img
						class="feature-img-placeholder"
						:src="featureImg[0]"
						alt="App screenshot"
					>
				</div>

				<p>
					With UI-licious Studio, you can:
				</p>

				<div class="feature">
					<div class="feature-icon">
						<i class="fa fa-play"/>
					</div>
					<div class="feature-text">
						Run tests on <span><i class="fa fa-chrome"/> Chrome</span>,
						<span><i class="fa fa-firefox"/> Firefox</span>, <span><i class="fa fa-safari"/> Safari</span>,
						<span><i class="fa fa-edge"/> Edge</span>, and <span><i
							class="fa fa-internet-explorer"
						/> IE
							11</span>
					</div>
				</div>

				<div class="feature">
					<div class="feature-icon --purple">
						<i class="fa fa-lock"/>
					</div>
					<div class="feature-text">
						Save tests in private projects
					</div>
				</div>

				<div class="feature">
					<div class="feature-icon --blue">
						<i class="fa fa-clock-o"/>
					</div>
					<div class="feature-text">
						Monitor your app continuously with scheduled tests
					</div>
				</div>

				<div class="feature">
					<div class="feature-icon --red">
						<i class="fa fa-bell"/>
					</div>
					<div class="feature-text">
						Setup alerts via Email, Slack or Webhooks
					</div>
				</div>

				<div class="feature">
					<div class="feature-icon">
            &nbsp;
					</div>
					<div class="feature-text">
						<a href="https://uilicious.com">...and more!</a>
					</div>
				</div>
			</section>

			<!-- Form -->
			<section class="sign-up-view-form-section">
				<cake-form
					id="sign-up-form"
					class="h-100 flex-col"
					action=""
				>
					<section class="flex-item-1 flex-col flex-v-space-between">
						<div v-show="form.step===1">
							<header class="mb-s">
								<h3 class="mb-xs">
									<b>Create an account</b>
								</h3>
								<p class="text-faded mb-0">
									Free 7-day trial. No credit card.
								</p>
							</header>

							<div
								v-if="form.error"
								class="alert alert-danger mb-s"
							>
								{{ form.error }}
							</div>

							<cake-bs-text-field-group
								v-model="form.field.name.value"
								label="Name"
								name="name"
								control-class="--translucent-light"
								:feedback="form.field.name.feedback"
							/>

							<cake-bs-text-field-group
								v-model="form.field.email.value"
								label="Email"
								name="email"
								type="email"
								control-class="--translucent-light"
								:feedback="form.field.email.feedback"
							/>

							<cake-bs-field-group
								label="Password"
								name="password"
								type="password"
								:feedback="form.field.password.feedback"
							>
								<cake-bs-text-field
									v-model="form.field.password.value"
									control-class="--translucent-light"
									type="password"
								/>
							</cake-bs-field-group>


							<div class="text-left">
								<div class="my-s">
									<cake-button
										class="primary large"
										:disabled="form.submitting"
										@click="step1"
									>
										{{ form.submitting ? "Hang on..." : "Sign up" }}
									</cake-button>
								</div>
							</div>
						</div>

						<div v-show="form.step===2">
							<header class="mb-s">
								<h3 class="mb-xs">
									<b>Before we get started...</b>
								</h3>
								<p class="text-faded mb-0"/>
							</header>

							<div
								v-if="form.error"
								class="alert alert-danger mb-s"
							>
								{{ form.error }}
							</div>

							<cake-bs-field-group
								label="What's your role?"
								name="role"
								:feedback="form.field.role.feedback"
							>
								<cake-dropdown-field
									v-model="form.field.role.value"
									:options="roleOptions"
									toggle-class="--translucent-light"
								/>
							</cake-bs-field-group>


							<cake-bs-text-field-group
								v-model="form.field.company.value"
								label="Company"
								name="company"
								control-class="--translucent-light"
								:feedback="form.field.company.feedback"
							/>

							<cake-bs-field-group
								label="Company Size"
								name="companySize"
								:feedback="form.field.companySize.feedback"
							>
								<cake-dropdown-field
									v-model="form.field.companySize.value"
									:options="companySizeOptions"
									toggle-class="--translucent-light"
								/>
							</cake-bs-field-group>

							<div class="text-left">
								<div class="my-s">
									<cake-button
										class="primary large"
										:disabled="form.submitting"
										@click="step2"
									>
										{{ form.submitting ? "Hold on..." : "Ok, let's go!" }}
									</cake-button>
								</div>
							</div>
						</div>

						<footer>
							<p>
								By creating an account, you agree to our <a href="https://uilicious.com/terms.html">terms
									of use</a>.
							</p>
							<p class="mb-0">
								Already have an account? <a href="https://user.uilicious.com"><b>Log in to
									Studio</b>.</a>
							</p>
						</footer>
					</section>
				</cake-form>
			</section>
		</div>
	</modal>
</template>
<style lang='scss' rel='stylesheet/scss'>
@import "~@uilicious/cake-ui/src/scss/colors";
@import "~@uilicious/cake-ui/src/scss/var";
@import "~@uilicious/cake-ui/src/scss/mixins";

$border-radius : 5px;

.sign-up-view {


  display        : flex;
  flex-direction : row;

  .sign-up-view-feature-section,
  .sign-up-view-form-section {
    padding : 2.5rem;
  }

  //--------------------------------------------------
  // Features
  //--------------------------------------------------
  .sign-up-view-feature-section {

    flex                      : 3;

    background                : white;
    color                     : black;

    border-top-left-radius    : $border-radius;
    border-bottom-left-radius : $border-radius;

    padding-left              : 4rem;
    padding-right             : 4rem;

    //--------------------------------------------------
    // Feature image
    //--------------------------------------------------

    .feature-img-box {

      position      : relative;

      max-width     : 100%;

      margin        : 2rem 0;

      background    : #eee;

      border        : 5px solid $color-brand;
      border-radius : 5px;

      box-shadow    : 1px 1px 2px 2px rgba(0, 0, 0, 0.1);

    }

    .feature-img {

      position  : absolute;
      top       : 0;
      max-width : 100%;

    }

    // placeholder to prevent the flicking that happens when the next image is being loaded
    .feature-img-placeholder {

      max-width : 100%;
      opacity   : 0;
    }

    //--------------------------------------------------
    // Feature text
    //--------------------------------------------------
    .feature {

      display        : flex;
      flex-direction : row;

      font-size      : 1em;
      line-height    : 2em;

      margin-bottom  : 1em;

      /*
      padding: .5rem;
      background: white;
      color: $color-brand;
      border-radius: 3px;
      */

      .feature-icon {
        text-align   : center;

        width        : 2em;
        margin-right : 1em;

        .fa {
          width            : 2em;
          padding          : .5em;
          background-color : $color-brand;
          color            : white;
          border-radius    : 5px;
        }

        &.--brand .fa {
          background-color : $color-brand;
        }

        &.--green .fa {
          background-color : $color-green;
        }

        &.--purple .fa {
          background-color : $color-purple;
        }

        &.--blue .fa {
          background-color : $color-blue;
        }

        &.--red .fa {
          background-color : $color-red;
        }

      }

    }

  }

  //--------------------------------------------------
  // Form
  //--------------------------------------------------
  .sign-up-view-form-section {

    flex                       : 2;

    background                 : $color-brand;
    background                 : -webkit-gradient(linear, left top, left bottom, from($color-brand), to(#09d4b3));
    background                 : linear-gradient(180deg, $color-brand 0, #09d4b3);
    color                      : white;
    border-top-right-radius    : $border-radius;
    border-bottom-right-radius : $border-radius;

    .form-text {
      color: white !important;
    }

    #sign-up-form {

      //--------------------------------------------------
      // Dropdown
      //--------------------------------------------------
      .dropdown {

      }

      //--------------------------------------------------
      // Footer
      //--------------------------------------------------
      footer {
        font-size : .8rem;
      }
    }

    //--------------------------------------------------
    // Muted text
    //--------------------------------------------------
    .text-faded {
      color   : white;
      opacity : .8;
    }

    //--------------------------------------------------
    // Links
    //--------------------------------------------------
    & a {
      color           : white;
      text-decoration : underline;
    }

    .form-control-feedback {
      padding: 0;
      background-color: transparent;
    }
  }

}

@include media-breakpoint-down(md) {

  /* Tablet and below */
  .sign-up-view {
    flex-direction : column;

    .sign-up-view-feature-section {
      border-top-left-radius     : $border-radius;
      border-top-right-radius    : $border-radius;
      border-bottom-left-radius  : 0 !important;
      border-bottom-right-radius : 0 !important;
    }

    .sign-up-view-form-section {
      border-top-left-radius     : 0 !important;
      border-top-right-radius    : 0 !important;
      border-bottom-left-radius  : $border-radius;
      border-bottom-right-radius : $border-radius;
    }

  }

}

</style>
<script type='text/babel'>

import FeatureProjectImg from "./feature-projects.png"
import FeatureBrowserImg from "./feature-browsers.png"
import FeatureJobsImg from "./feature-jobs.png"
import FeatureReportsImg from "./feature-reports.png"
import {trackSignupEvent} from "@uil/analytics/google_analytics"

export default {
	//----------------------------------
	// Properties
	//----------------------------------
	data: function () {
		return {
			// Feature image animation,
			currentFeatureImgIdx: 0,
			featureImg:           [
				FeatureProjectImg,
				FeatureBrowserImg,
				FeatureJobsImg,
				FeatureReportsImg
			],
			// Form
			form: {
				step:  1,
				error: "",
				field: {
					name: {
						value:    "",
						feedback: null
					},
					email: {
						value:    "",
						feedback: null
					},
					password: {
						value:    "",
						feedback: {
							message: "At least 12 characters"
						}
					},
					role: {
						value:    "",
						feedback: null
					},
					company: {
						value:    "",
						feedback: null
					},
					companySize: {
						value:    "",
						feedback: null
					}
				},
				submitting: false
			},
			// Form options
			roleOptions: [
				{
					value: "CTO"
				},
				{
					value: "Product Management"
				},
				{
					value: "Quality Assurance"
				},
				{
					value: "Engineering"
				},
				{
					value: "Design"
				},
				{
					value: "Sales & Marketing"
				},
				{
					value: "Founder/CEO"
				},
				{
					value: "Others"
				},
			],
			companySizeOptions: [
				{
					value: "1 - 4 employees"
				},
				{
					value: "5 - 9 employees"
				},
				{
					value: "10 - 49 employees"
				},
				{
					value: "50 - 199 employees"
				},
				{
					value: "200 - 499 employees"
				},
				{
					value: "500 - 999 employees"
				},
				{
					value: "1000+ employees"
				}
			],
		}
	},
	computed: {
		currentFeatureImg() {
			return this.featureImg[this.currentFeatureImgIdx % this.featureImg.length]
		}
	},
	watch: {
		"form.field.password.value": function () {
			if (this.form.field.password.value.length >= 12) {
				this.form.field.password.feedback.status = "success"
				this.form.field.password.feedback.message = "Perfect!"
			} else {
				this.form.field.password.feedback.message = "At least 12 characters"
			}
		}
	},
	//----------------------------------
	// Lifecycle Hooks
	//----------------------------------
	mounted() {

		this.$slideshow = window.setInterval(() => {
			this.currentFeatureImgIdx++
		}, 3000)

	},
	beforeDestroy() {
		window.clearInterval(this.$slideshow)
	},
	//----------------------------------
	// Methods
	//----------------------------------
	methods: {
		step1() {
			this.form.submitting = true

			new Promise(
				(resolve, reject) => {

					// reset feedback
					this.form.field.name.feedback = null
					this.form.field.email.feedback = null
					this.form.field.password.feedback = null

					// validate
					let valid = true

					if (this.form.field.name.value === "") {
						this.form.field.name.feedback = {
							status:  "error",
							message: "Required"
						}
						valid = false
					}

					if (this.form.field.password.value.length < 12) {
						this.form.field.password.feedback = {
							status:  "error",
							message: "At least 12 characters"
						}
						valid = false
					}

					// Check if email is empty and if email is in email format
					this.checkEmail()
						.then((valid) => {
							if (valid) {
								this.form.error = null
								resolve()
							} else {
								reject()
							}
						})
						.catch(reject)

				})
				.then(() => {

					this.form.step = 2
					this.form.submitting = false

				})
				.catch((e) => {
					console.error("error", e)

					this.form.submitting = false
				})

		},
		step2() {

			this.form.submitting = true

			new Promise(
				(resolve, reject) => {

					// reset feedback
					this.form.field.role.feedback = null

					// validate
					let valid = true

					if (this.form.field.role.value === null || this.form.field.role.value === "") {
						this.form.field.role.feedback = {
							status:  "error",
							message: "Required"
						}
						valid = false
					}

					if (valid) {
						resolve()
					} else {
						reject()
					}

				})
				.then(() => {

					// register user
					let reqData = {
						email:       this.form.field.email.value,
						name:        this.form.field.name.value,
						password:    this.form.field.password.value,
						role:        this.form.field.role.value,
						companyName: this.form.field.company.value,
						companySize: this.form.field.companySize.value,
						source:      "SNIPPETS"
					}

					this.$api.account.new(reqData)
						.then((res) => {

							// affiliate referrer tracking
							if (window.fpr) {
								window.fpr("referral", {email: reqData.email})
							}

							// success! track sign up
							trackSignupEvent()

							// do login
							return this.$api.account.login({
								loginName: reqData.email,
								password:  reqData.password,
							})

						})
						.then((res) => {

							// get user info
							return this.$api.account.info.get()

						})
						.then((res) => {

							// redirect to Studio

							if (_.isNil(res.result)) {
								console.error("Missing `result` from API response")
								throw new Error("We are facing some technical difficulties, please try again later.")
							}

							let hostUrl = res.result.hostURL
							if (_.isNil(hostUrl)) {
								console.error("Missing `hostURL`")
								throw new Error("We are facing some technical difficulties, please try again later.")
							}

							// redirect to studio
							window.location.href = res.result.hostURL

						})
						.catch((e) => {

							// handle email taken
							if (e.code === "EMAIL_TAKEN") {
								e.message = `You already have an account with the email ${reqData.email}.`
							}

							// show error
							this.form.error = (e && e.message) || "We are facing some technical difficulties, please try again later."
							this.form.submitting = false
							throw e

						})


				})
				.catch((e) => {
					this.form.submitting = false
				})


		},

		////////////////////////////////////
		//
		// Validation methods
		//
		////////////////////////////////////

		checkEmail() {
			return new Promise(
				(resolve, reject) => {

					if (this.form.field.email.value === "") {

						this.form.field.email.feedback = {
							status:  "error",
							message: "Required"
						}
						resolve(false)
					} else {

						// check if email has been taken
						this.$api.account.new.isEmailAvailable({email: this.form.field.email.value})
							.then((res) => {

								if (_.isNil(res.result)) {
									console.error("Missing `result` from API response")
									throw new Error("We are facing some technical difficulties, please try again later.")
								}

								let emailAvailable = res.result
								if (!emailAvailable) {
									this.form.field.email.feedback = {
										status:  "error",
										message: "You already have an account. :)"
									}
									resolve(false)
								}
								resolve(true)

							})
							.catch((e) => {

								this.form.error = (e && e.message) || "We are facing some technical difficulties, please try again later."
								return reject(e)

							})
					}

				})
		},
		close() {
			this.$emit("close")
		},
	},
}
</script>

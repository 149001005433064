var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      attrs: {
        title: "Create an account",
        "enable-mask-dismissal": true,
        "show-header": false,
        "show-footer": false,
        "pad-content": false,
      },
      on: { close: _vm.close },
    },
    [
      _c(
        "div",
        {
          staticClass: "sign-up-view",
          attrs: { slot: "content" },
          slot: "content",
        },
        [
          _c("section", { staticClass: "sign-up-view-feature-section" }, [
            _c("header", { staticClass: "text-center" }, [
              _c("h3", { staticClass: "mb-xs" }, [
                _c("b", [_vm._v("Go Pro")]),
                _vm._v(" with UI-licious Studio\n\t\t\t\t\t"),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n\t\t\t\t\t\tAutomate user journey testing and ship with confidence!\n\t\t\t\t\t"
                ),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "feature-img-box" },
              [
                _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
                  _c("img", {
                    key: _vm.currentFeatureImg,
                    staticClass: "feature-img",
                    attrs: {
                      src: _vm.currentFeatureImg,
                      alt: "App screenshot",
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("img", {
                  staticClass: "feature-img-placeholder",
                  attrs: { src: _vm.featureImg[0], alt: "App screenshot" },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("p", [
              _vm._v("\n\t\t\t\t\tWith UI-licious Studio, you can:\n\t\t\t\t"),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "feature" }, [
              _c("div", { staticClass: "feature-icon" }, [
                _c("i", { staticClass: "fa fa-play" }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "feature-text" }, [
                _vm._v("\n\t\t\t\t\t\tRun tests on "),
                _c("span", [
                  _c("i", { staticClass: "fa fa-chrome" }),
                  _vm._v(" Chrome"),
                ]),
                _vm._v(",\n\t\t\t\t\t\t"),
                _c("span", [
                  _c("i", { staticClass: "fa fa-firefox" }),
                  _vm._v(" Firefox"),
                ]),
                _vm._v(", "),
                _c("span", [
                  _c("i", { staticClass: "fa fa-safari" }),
                  _vm._v(" Safari"),
                ]),
                _vm._v(",\n\t\t\t\t\t\t"),
                _c("span", [
                  _c("i", { staticClass: "fa fa-edge" }),
                  _vm._v(" Edge"),
                ]),
                _vm._v(", and "),
                _c("span", [
                  _c("i", { staticClass: "fa fa-internet-explorer" }),
                  _vm._v(" IE\n\t\t\t\t\t\t\t11"),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "feature" }, [
              _c("div", { staticClass: "feature-icon --purple" }, [
                _c("i", { staticClass: "fa fa-lock" }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "feature-text" }, [
                _vm._v(
                  "\n\t\t\t\t\t\tSave tests in private projects\n\t\t\t\t\t"
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "feature" }, [
              _c("div", { staticClass: "feature-icon --blue" }, [
                _c("i", { staticClass: "fa fa-clock-o" }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "feature-text" }, [
                _vm._v(
                  "\n\t\t\t\t\t\tMonitor your app continuously with scheduled tests\n\t\t\t\t\t"
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "feature" }, [
              _c("div", { staticClass: "feature-icon --red" }, [
                _c("i", { staticClass: "fa fa-bell" }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "feature-text" }, [
                _vm._v(
                  "\n\t\t\t\t\t\tSetup alerts via Email, Slack or Webhooks\n\t\t\t\t\t"
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "feature" }, [
              _c("div", { staticClass: "feature-icon" }, [
                _vm._v("\n             \n\t\t\t\t\t"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "feature-text" }, [
                _c("a", { attrs: { href: "https://uilicious.com" } }, [
                  _vm._v("...and more!"),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "section",
            { staticClass: "sign-up-view-form-section" },
            [
              _c(
                "cake-form",
                {
                  staticClass: "h-100 flex-col",
                  attrs: { id: "sign-up-form", action: "" },
                },
                [
                  _c(
                    "section",
                    {
                      staticClass: "flex-item-1 flex-col flex-v-space-between",
                    },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.form.step === 1,
                              expression: "form.step===1",
                            },
                          ],
                        },
                        [
                          _c("header", { staticClass: "mb-s" }, [
                            _c("h3", { staticClass: "mb-xs" }, [
                              _c("b", [_vm._v("Create an account")]),
                            ]),
                            _vm._v(" "),
                            _c("p", { staticClass: "text-faded mb-0" }, [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t\t\tFree 7-day trial. No credit card.\n\t\t\t\t\t\t\t\t"
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _vm.form.error
                            ? _c(
                                "div",
                                { staticClass: "alert alert-danger mb-s" },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t\t" +
                                      _vm._s(_vm.form.error) +
                                      "\n\t\t\t\t\t\t\t"
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c("cake-bs-text-field-group", {
                            attrs: {
                              label: "Name",
                              name: "name",
                              "control-class": "--translucent-light",
                              feedback: _vm.form.field.name.feedback,
                            },
                            model: {
                              value: _vm.form.field.name.value,
                              callback: function ($$v) {
                                _vm.$set(_vm.form.field.name, "value", $$v)
                              },
                              expression: "form.field.name.value",
                            },
                          }),
                          _vm._v(" "),
                          _c("cake-bs-text-field-group", {
                            attrs: {
                              label: "Email",
                              name: "email",
                              type: "email",
                              "control-class": "--translucent-light",
                              feedback: _vm.form.field.email.feedback,
                            },
                            model: {
                              value: _vm.form.field.email.value,
                              callback: function ($$v) {
                                _vm.$set(_vm.form.field.email, "value", $$v)
                              },
                              expression: "form.field.email.value",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "cake-bs-field-group",
                            {
                              attrs: {
                                label: "Password",
                                name: "password",
                                type: "password",
                                feedback: _vm.form.field.password.feedback,
                              },
                            },
                            [
                              _c("cake-bs-text-field", {
                                attrs: {
                                  "control-class": "--translucent-light",
                                  type: "password",
                                },
                                model: {
                                  value: _vm.form.field.password.value,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form.field.password,
                                      "value",
                                      $$v
                                    )
                                  },
                                  expression: "form.field.password.value",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "text-left" }, [
                            _c(
                              "div",
                              { staticClass: "my-s" },
                              [
                                _c(
                                  "cake-button",
                                  {
                                    staticClass: "primary large",
                                    attrs: { disabled: _vm.form.submitting },
                                    on: { click: _vm.step1 },
                                  },
                                  [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t\t\t\t" +
                                        _vm._s(
                                          _vm.form.submitting
                                            ? "Hang on..."
                                            : "Sign up"
                                        ) +
                                        "\n\t\t\t\t\t\t\t\t\t"
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.form.step === 2,
                              expression: "form.step===2",
                            },
                          ],
                        },
                        [
                          _c("header", { staticClass: "mb-s" }, [
                            _c("h3", { staticClass: "mb-xs" }, [
                              _c("b", [_vm._v("Before we get started...")]),
                            ]),
                            _vm._v(" "),
                            _c("p", { staticClass: "text-faded mb-0" }),
                          ]),
                          _vm._v(" "),
                          _vm.form.error
                            ? _c(
                                "div",
                                { staticClass: "alert alert-danger mb-s" },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t\t" +
                                      _vm._s(_vm.form.error) +
                                      "\n\t\t\t\t\t\t\t"
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "cake-bs-field-group",
                            {
                              attrs: {
                                label: "What's your role?",
                                name: "role",
                                feedback: _vm.form.field.role.feedback,
                              },
                            },
                            [
                              _c("cake-dropdown-field", {
                                attrs: {
                                  options: _vm.roleOptions,
                                  "toggle-class": "--translucent-light",
                                },
                                model: {
                                  value: _vm.form.field.role.value,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form.field.role, "value", $$v)
                                  },
                                  expression: "form.field.role.value",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("cake-bs-text-field-group", {
                            attrs: {
                              label: "Company",
                              name: "company",
                              "control-class": "--translucent-light",
                              feedback: _vm.form.field.company.feedback,
                            },
                            model: {
                              value: _vm.form.field.company.value,
                              callback: function ($$v) {
                                _vm.$set(_vm.form.field.company, "value", $$v)
                              },
                              expression: "form.field.company.value",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "cake-bs-field-group",
                            {
                              attrs: {
                                label: "Company Size",
                                name: "companySize",
                                feedback: _vm.form.field.companySize.feedback,
                              },
                            },
                            [
                              _c("cake-dropdown-field", {
                                attrs: {
                                  options: _vm.companySizeOptions,
                                  "toggle-class": "--translucent-light",
                                },
                                model: {
                                  value: _vm.form.field.companySize.value,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form.field.companySize,
                                      "value",
                                      $$v
                                    )
                                  },
                                  expression: "form.field.companySize.value",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "text-left" }, [
                            _c(
                              "div",
                              { staticClass: "my-s" },
                              [
                                _c(
                                  "cake-button",
                                  {
                                    staticClass: "primary large",
                                    attrs: { disabled: _vm.form.submitting },
                                    on: { click: _vm.step2 },
                                  },
                                  [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t\t\t\t" +
                                        _vm._s(
                                          _vm.form.submitting
                                            ? "Hold on..."
                                            : "Ok, let's go!"
                                        ) +
                                        "\n\t\t\t\t\t\t\t\t\t"
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("footer", [
                        _c("p", [
                          _vm._v(
                            "\n\t\t\t\t\t\t\t\tBy creating an account, you agree to our "
                          ),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "https://uilicious.com/terms.html",
                              },
                            },
                            [_vm._v("terms\n\t\t\t\t\t\t\t\t\tof use")]
                          ),
                          _vm._v(".\n\t\t\t\t\t\t\t"),
                        ]),
                        _vm._v(" "),
                        _c("p", { staticClass: "mb-0" }, [
                          _vm._v("\n\t\t\t\t\t\t\t\tAlready have an account? "),
                          _c(
                            "a",
                            { attrs: { href: "https://user.uilicious.com" } },
                            [
                              _c("b", [
                                _vm._v("Log in to\n\t\t\t\t\t\t\t\t\tStudio"),
                              ]),
                              _vm._v("."),
                            ]
                          ),
                        ]),
                      ]),
                    ]
                  ),
                ]
              ),
            ],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
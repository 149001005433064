import {mutate, get, perform} from "./types"
import {CakeModels} from "@uilicious/cake-ui"

const {Rectangle} = CakeModels

//-------------------------------
// STATE
//-------------------------------

const state = {
	initialising: false,
	browser:      "chrome",	//default
	resolution:   new Rectangle(1280, 960), //default
	testRunId:    null,
	scriptName:   "",
}

//-------------------------------
// MUTATIONS
//-------------------------------

const mutations = {
	[mutate.TEST_RUNNER_CONTROLLER.SET_INITIALISING](state, initialising) {
		state.initialising = initialising
	},
	[mutate.TEST_RUNNER_CONTROLLER.SET_BROWSER](state, browser) {
		state.browser = browser
	},
	[mutate.TEST_RUNNER_CONTROLLER.SET_RESOLUTION](state, resolution) {
		state.resolution = resolution
	},
	[mutate.TEST_RUNNER_CONTROLLER.SET_TEST_RUN_ID](state, testRunId) {
		state.testRunId = testRunId
	},
	[mutate.TEST_RUNNER_CONTROLLER.UNSET_TEST_RUN_ID](state) {
		state.testRunId = null
	},
	[mutate.TEST_RUNNER_CONTROLLER.SET_SCRIPT_NAME](state, scriptName) {
		state.scriptName = scriptName
	},
}

//-------------------------------
// GETTERS
//-------------------------------

const getters = {
	[get.TEST_RUNNER_CONTROLLER.TEST_RUN](state, getters, rootState) {

		let testRunId = state.testRunId

		if (_.isNil(testRunId)) {
			return null
		}

		let testRun = rootState.testRuns[testRunId]
		if (_.isNil(testRun)) {
			return null
		}

		return testRun

	}
}

//-------------------------------
// ACTIONS
//-------------------------------

const actions = {}

//-------------------------------
// STORE
//-------------------------------

export default {
	state,
	mutations,
	getters,
	actions
}

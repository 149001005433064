import {mutate, get, perform} from "./types"

const state = {
	mobileView: {
		activePane: "script" // by default show the script
	}
}

const mutations = {
	[mutate.EDITOR_VIEW.SET_MOBILE_VIEW_ACTIVE_PANE]: function(state, view){
		state.mobileView.activePane = view
	}
}

const getters = {
}

const actions = {
}

export default {
	state,
	mutations,
	getters,
	actions
}
